import React from 'react';
import AboutView from '../../components/about/AboutView';
function about() {
  return (
    <>
      <AboutView />
    </>
  );
}

export default about;
