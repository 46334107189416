import styled from 'styled-components';

export const SectionOne = styled.div`


  @media (max-width: 768px) {
    padding: 2em 2em 1.5em;
  
  }
`;

export const SectionOneInnerDiv = styled.div`
  margin: 100px 70px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin: 120px 30px 0 30px;
  }
`;

export const SectionOneHeading = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

export const SectionOneCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 70px;
  border: 3px #031461 solid;
  border-radius: 20px;
  padding: 40px;
  background-color: rgba(189, 189, 189, 0.7);

  @media (max-width: 768px) {
    margin: 40px 20px;
    padding: 20px;
  }
`;

/* Section 2 */
export const SectionTwoMain = styled.div`
  margin: 70px 100px;

  @media (max-width: 768px) {
    margin: 50px 20px;
  }
`;

export const SectionTwoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const SectionTwoCard = styled.div`
  border-radius: 20px 20px 0 0;
  border: white 2px solid;
  box-shadow: 0px 0px 10px rgb(77, 51, 17);
`;

export const SectionTwoCardImg = styled.img`
  border-radius: 20px 20px 0 0;
  height:200px;
  width:100%;

`;

export const SectionTwoCardContent = styled.div`
  margin: 0 10px;
`;

export const ServiceReadMoreButton = styled.div`
  border: green 1px solid;
  margin: 10px;
  background-color: inherit;
  padding: 0 5px;
  position: relative;
  display: block;
  width: calc(100% - 20px);
  box-sizing: border-box;

  &:hover {
    border-color: yellow;
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) skewX(-45deg) rotate(-45deg);
    transition: top 0.3s ease;

    &:hover {
      top: calc(50% - 5px);
    }
  }
`;

/* Section 3 */
export const SectionThreePallarax = styled.div`

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const SectionThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 70px 40px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 40px;

  @media (max-width: 768px) {
    margin: 20px 0px;
    padding: 20px;
  }
`;

export const SectionThreeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const SectionThreeCard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: white 4px solid;
  padding: 10px;
  box-shadow: 0 0 10px rgb(77, 51, 17);
  border-radius: 20px 0 20px 0;
`;

/* Section 4 */
export const SectionFour = styled.div`


  @media (max-width: 768px) {
    padding: 2em 2em 1.5em;
  }
`;

export const SectionFourContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(247, 222, 192, 0.5);
  margin: 0 20px;
  padding: 20px 0;
  border-radius: 0 70px 0 70px;

  @media (max-width: 768px) {
    margin: 0 20px;
    padding: 20px;
  }
`;

export const SectionFourDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
    img {
    width: 100%;
    height: 400px;
    border-radius: 0 70px 0 70px;
  }


  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
     border-radius: 0 30px 0 30px;
      img {
    width: 100%;
    height: 200px;
    border-radius: 0 30px 030px;
  }

  }
`;

export const SectionFourItem = styled.div`
  width: 100%;
  padding: 10px;


  hr {
    transform: rotate(90deg);
    border: none;
    border-top: 3px solid rgb(128, 128, 128);
    width: 20%;
    margin: 0 auto;
  }
`;

