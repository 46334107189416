import React from 'react';
import HomeView from '../../components/Home/HomeView';

function home() {
  return (
    <>
      <HomeView />
    </>
  );
}

export default home;
