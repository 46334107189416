import styled from 'styled-components';

// Main container
export const AboutSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
margin: 140px 90px 20px 90px; 
gap:40px;
  padding: 20px;
 
  text-align: center; 
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    text-align:left;
    margin: 0 auto; /* Center the paragraph */
  }
  @media (max-width: 768px) {
  margin: 140px 10px 20px 10px; 
    flex-direction: column;
  }
`;

// Sidebar navigation container
export const Sidebar = styled.nav`
  width: 25%;
  color: brown;
  max-height: 310px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

// Navigation list items
export const NavigationList = styled.ul`
  list-style-type: none;
  padding: 10px;
  margin: 0;
`;// Navigation item with svg icon and brown <hr> at the bottom
export const NavigationItem = styled.li`
  margin: 15px 0;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center; 
  position: relative;
   padding-bottom:10px;

  svg {
    margin-right: 10px; 
   
  }

  &:hover {
    color: #1abc9c;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  // Brown horizontal rule at the bottom
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: brown;
    position: absolute;
    bottom: 0; 
    left: 0;   
  }
`;



// Content area to display selected section information
export const Content = styled.div`
  width: 60%;
  padding:0 20px;
  background-color: white;
text-align:left; 
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

// Section title
export const AboutTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: brown;
  padding:0;
text-align:left;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

// Text paragraph
export const AboutText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: #7f8c8d;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// Info section style for the content container
export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    td {
      padding: 10px;
      border: 1px solid #bdc3c7;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
