import React from 'react';
import ServicesView from '../../components/services';
function Services() {
  return (
    <div>
      <ServicesView />
    </div>
  );
}

export default Services;
