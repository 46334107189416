import React, { useState } from 'react';
import {
  AboutSection,
  Sidebar,
  Content,
  NavigationItem,
  NavigationList,
  InfoSection,
  AboutTitle,
  AboutText,
} from './AboutViewStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookAtlas,
  faEye,
  faFistRaised,
  faShieldAlt,
  faLightbulb,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';
import './About.css';
import ceo_img from '../../assets/images/ceo.png'
const AboutView = () => {
  const [selectedSection, setSelectedSection] = useState('about-us');
  const [isAccordionOpen, setAccordionOpen] = useState({
    academics: false,
    experience: false,
    successfulProjects: false,
    preCareerExperience :false,
  });

  const toggleAccordion = (section) => {
    setAccordionOpen((prevState) => ({
      academics: section === 'academics' ? !prevState.academics : false,
      experience: section === 'experience' ? !prevState.experience : false,
      successfulProjects: section === 'successfulProjects' ? !prevState.successfulProjects : false,
      preCareerExperience : section === 'preCareerExperience ' ? !prevState.preCareerExperience  : false,
    }));
  };
  
  const renderContent = () => {
    switch (selectedSection) {
      case 'about-us':
        return (
          <InfoSection>
            <AboutTitle>Boron GeoScience</AboutTitle>
            <AboutText>
              A Namibian based independent mineral exploration close corporation
              consultancy with primary focus on the technical project management and 
              operational execution. The corporation is oriented at providing conventional 
              technical services in the mineral industry to junior and publicly listed 
              corporations who adhere to international certification standards.
            </AboutText>
            <AboutText> 
              BGS is well situated to helping your corporation meet its objectives and 
              operate smoothly in country. 
            </AboutText>
          </InfoSection>
        );
        case 'management':
          return (
            <InfoSection>
              <AboutTitle>Management</AboutTitle>
              <div className='InfoSection_img'style={{ display: 'flex', alignItems: 'flex-start' }}>
                {/* Picture Section */}
                <img
                  src={ceo_img}
                  alt="Bonifacius K Katanga"
                  style={{ width: '150px', height: 'auto', marginRight: '20px', borderRadius: '5px' }}
                />
        
                {/* Accordion Section */}
                <div>
                  {/* Academics Accordion */}
                  <h3 onClick={() => toggleAccordion('academics')} style={{ color: 'red', cursor: 'pointer' }}>
                    Academics
                  </h3>
                  {isAccordionOpen.academics && (
                    <p>B.Sc. Chemistry & Geology, ArcGIS cert.</p>
                  )}
        
                  {/* Experience Accordion */}
                  <h3 onClick={() => toggleAccordion('experience')} style={{ color: 'red', cursor: 'pointer' }}>
                    Experience
                  </h3>
                  {isAccordionOpen.experience && (
                    <div>
                      <p><strong>Years:</strong> 14 Years.</p>
                      <p>
                        <strong>Commodities:</strong> Base & rare metals (REE, Lithium), Industrial minerals,
                        semi-precious & Precious stones, Nuclear Fuels & precious metals.
                      </p>
                    </div>
                  )}
        
                  {/* Successful Projects Accordion */}
                  <h3 onClick={() => toggleAccordion('successfulProjects')} style={{ color: 'red', cursor: 'pointer' }}>
                    Successful Projects
                  </h3>
                  {isAccordionOpen.successfulProjects && (
                    <ul>
                      <li>Lofdal HREE from discovery to PEA.</li>
                      <li>WIA Gold (Okombahe), Navachab Gold Mine (Underground Trial 
                      Mining), Uis Tin Mine, Epembe Nb, Ta deposit, Ondoto/ 
                      Swartbooisdrift LREE (discovery), </li>
                      <li>Teufelskuppe LREE, Ongombo Au, 
                      Cu, & Ag deposit, Witvlei Cu, Au, Ag & DOF Cobalt.</li>
                    </ul>
                  )}
                  {/* Pre-career Experience  Accordion */}
                  <h3 onClick={() => toggleAccordion('preCareerExperience ')} style={{ color: 'red', cursor: 'pointer' }}>
                  Pre-career Experience 
                  </h3>
                  {isAccordionOpen.preCareerExperience  && (
                    <ul>
                      <li>Mr Katanga Bonifacius Katanga began with notable exposure in mineral exploration and mining in 2007, a year he participated in 
                        mapping of the Otjozondu manganese seam, geochemical soil sampling at Omitiomire copper deposit, geochemical soil sampling 
                        at Berg Aukas mine with BGR – a German corporation and mapping of Rehoboth gold/copper occurrences in 2009 as a student, 
                        after which he was awarded two conjugated Bachelors of Science degrees in Geology and Chemistry from the University of 
                        Namibia</li> 
                    </ul>
                  )}
                </div>
              </div>
            </InfoSection>
          );
        
      case 'values':
        return (
          <InfoSection>
            <AboutTitle>Values</AboutTitle>
            <AboutText>BGS is underpinned by three beneficial values that aim to enhance good 
              work ethics to ensure positive change is achieved via solid loyalty, integrity 
              and commitment- whose beneficiaries are our team members, clients and 
              communities we operate in
              </AboutText>
            <AboutTitle>HRD | Human Resource Development </AboutTitle>
            <AboutText>Human resource development is highly encouraged for there is no great reward in idleness but through hard work, dedication and 
              constant improvements. Worry not for we consider your circumstance with fellow feeling. Therefore, we urge our partners 
              personal advancement to be driven at all times by honour, respect and dependability. The good condition of heart and happiness 
              of our people is very dear to us.
              </AboutText>
              <AboutTitle>Hard Work and Excellence  </AboutTitle>
            <AboutText>Hard work pays as the saying goes but excellence is even higher principle for it reminds us to strive for greatness to surpass the 
              expectations of our clients, guaranteeing superior service delivery. BGS bares in mind professional development, enhancements 
              of capabilities by being creative in our approaches to challenging circumstances. Change is in effect brought by adaptability to 
              ever changing circumstances in the mineral world.
              </AboutText>
              <AboutTitle>Dependability  </AboutTitle>
            <AboutText>Dependability is a moral principle we strongly live by and guide our actions. It helps us deliver quality work and take pride in being 
              accountable for what we do which result in a motivated and ever enduring team. We shift no blame on no one when pitfalls 
              manifests in the course of the programme but rather stay oriented to fine tuning our oversight to ensure a well-polished and top
              notch service provision.
              </AboutText>
              <AboutTitle>Benevolence and Love for All </AboutTitle>
            <AboutText> 
              BGS emphasizes compassionate, opportunity and fairness treatment for people of all backgrounds. We believe diversity and 
              inclusiveness yields an environment whereby team members take ownership of what they have been given under their care and 
              be proud of what they stand for, which in turn aid them to achieve their full potential. Together we stand but apart we fall, it goes 
              without saying, thus, we edge all to visit our career page for opportunities at BGS.
              </AboutText>
          </InfoSection>
        );
      case 'health-safety':
        return (
          <InfoSection>
            <AboutTitle>Health-Safety</AboutTitle>
            <AboutText> 
            BGS highly emphasizes the health & safety of our team members and partners. We are committed to high standards of health and 
            safety by implementing educative programs in accordance with government safety laws and strictly adhere to our clients’ safety 
            standards. Therefore, our philosophy is all our work is guided by “site specific” standard operating procedures that clearly outline 
            the hazards associated with the activities performed by our team members and partners, and well embedded checklist, as well as 
            mitigation measures. 
            </AboutText>

          </InfoSection>
        );
      default:
        return null;
    }
  };

  return (
    <AboutSection>
      <Sidebar>
        <h2 className='sidebar_header'> About us </h2>
        <NavigationList>
          <NavigationItem onClick={() => setSelectedSection('about-us')}>
            <FontAwesomeIcon icon={faGlobe} />
            BGS
          </NavigationItem>
          <NavigationItem onClick={() => setSelectedSection('management')}>
            <FontAwesomeIcon icon={faBookAtlas} />
            Management
          </NavigationItem>
          <NavigationItem onClick={() => setSelectedSection('values')}>
            <FontAwesomeIcon icon={faEye} />
            Values
          </NavigationItem>
          <NavigationItem onClick={() => setSelectedSection('health-safety')}>
            <FontAwesomeIcon icon={faFistRaised} />
            Health & Safety
          </NavigationItem>
        </NavigationList>
      </Sidebar>
      <Content>
        {renderContent()}
      </Content>
    </AboutSection>
  );
};

export default AboutView;
