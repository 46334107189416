import styled from 'styled-components';

export const theme = {
  textColor: '#000',
  fontSize: '1rem',
  fontWeight: '700',
  hoverColor: '#f90', // hover text color like the image (orange)
  transition: '0.3s ease-in-out',
};

// Black Strip on Top
export const TopBlackStrip = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12px;
  background-color: #000;
  z-index: 1000;
`;

// White Strip Below the Black Strip
export const WhiteStrip = styled.div`
  position: fixed;
  top: 12px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: 999;
`;

// Existing NavBar Top Strip (Turquoise)
export const NavBarTopStrip = styled.div`
  position: fixed;
  top: 13px; /* Below the white strip */
  left: 0;
  width: 100%;
  background-color: #00a2a9; /* Turquoise top strip */
  z-index: 998;
`;

// Orange Strip Below the Turquoise Strip
export const OrangeStrip = styled.div`
  position: fixed;
  top: 41px; /* Below the turquoise strip */
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f90;
  z-index: 997;
`;

export const NavBarContainer = styled.nav`
  width: 100%;
  position: fixed; /* Make navbar fixed */
  top: 37px; /* Adjusted to appear below the orange strip */
  background-color: #fff; /* White background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 996;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 96px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const NavBarLogo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 5px;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
`;

export const CompanyName = styled.h4`
  font-size: 1rem;
  font-weight: 700;
  color: #000; /* Black text */
`;

export const Tagline = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #f90; /* Orange color like the image */
`;

export const CountryLabel = styled.h3`
  font-size: 1.1rem;
  text-align: right;
  color: #fff;
  margin: 0;
  padding: 2px 70px 2px 0px;
`;

// Nav Bar List - Fullscreen Drawer Styling

export const NavBarList = styled.ul`

  display: flex;
  align-items: center;
  list-style: none;

  @media (max-width: 768px) {
    position: fixed;
    top: 142px;
    right: 0;
    height: 40vh;
    width: 50%;
    background-color: #fff;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    transform: ${({ clicked }) => (clicked ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    visibility: ${({ clicked }) => (clicked ? 'visible' : 'hidden')};
    z-index: 3000; /* Set to higher than the overlay */
  }
`;
export const NavBarItem = styled.li`
  padding: 15px 20px;
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontWeight};
  text-transform: uppercase;
  color: ${({ theme }) => theme.textColor};
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.hoverColor}; /* Orange hover effect */
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

// Mobile Button (Hamburger Icon)
export const MobileButton = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    color: #000;
    z-index: 2001;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
  }
`;

// Header above the NavBar list (WHY BGS?  CAREERS)
export const NavBarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  margin-top: 25px;

  h2 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    margin: 0;
  }

  @media (max-width: 768px) {
    display: none;
    margin-top: 0;
  }
`;

// Overlay for clicking outside of the drawer

export const Overlay = styled.div`
display:none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: ${({ clicked }) => (clicked ? 'visible' : 'hidden')};
  opacity: ${({ clicked }) => (clicked ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  z-index: 2; /* Ensure it's behind the NavBarList */
    @media (max-width: 768px) {
    display: block;

  }
`;

