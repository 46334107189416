import React from 'react';
import ContactView from '../../components/Contact';
function Contact() {
  return (
    <>
      <ContactView />
    </>
  );
}

export default Contact;
