import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from '../../assets/images/logo_no_background.png';
import {
  NavBarContainer,
  NavBarList,
  NavBarItem,
  MobileButton,
  NavBarTopStrip,
  NavBarLogo,
  TopBlackStrip,
  WhiteStrip,
  OrangeStrip,
  Tagline,
  CountryLabel,
  CompanyInfo,
  CompanyName,
  NavBarHeader,
  Overlay
} from './NavBarStyles';

import { ThemeProvider } from 'styled-components';
import { theme } from './NavBarStyles';

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleNavLinkClick = (path) => {
    handleClick();
    navigate(path);
  };

  const handleOverlayClick = () => {
    setClicked(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* Top Black Strip */}
        <TopBlackStrip />
        {/* White Strip */}
        <WhiteStrip />
        {/* Turquoise and Orange Strips */}
        <NavBarTopStrip>
          <CountryLabel>Namibia!</CountryLabel>
        </NavBarTopStrip>
        <OrangeStrip />

        {/* Navigation Overlay (when clicking outside drawer) */}
        <Overlay clicked={clicked} onClick={handleOverlayClick} />

        <NavBarContainer>
          {/* Logo and Company Info */}
          <NavBarLogo>
            <Link to='/'>
              <img src={logoImage} alt='Logo' style={{ height: '100px' }} />
            </Link>
            <CompanyInfo>
              <CompanyName>Boron Geoscience Services cc</CompanyName>
              <Tagline>HONEST | TRUSTED | PROSPECTORS</Tagline>
            </CompanyInfo>
          </NavBarLogo>

          {/* Header above NavBar List */}
          <div>
          <NavBarHeader>
            <h2>WHY BGS?  CAREERS</h2>
          </NavBarHeader>

          {/* Navigation List (Drawer on Mobile) */}
          <NavBarList clicked={clicked}>
            {/* Close button inside the drawer */}
            

            <NavBarItem onClick={() => handleNavLinkClick('/company')}>
              COMPANY
            </NavBarItem>
            <NavBarItem onClick={() => handleNavLinkClick('/about')}>
              ABOUT US
            </NavBarItem>
            <NavBarItem onClick={() => handleNavLinkClick('/services')}>
              SERVICES
            </NavBarItem>
            <NavBarItem onClick={() => handleNavLinkClick('/contact')}>
              CONTACT US
            </NavBarItem>
          </NavBarList>
          </div>
          

          {/* Mobile Burger Button */}
          <MobileButton onClick={handleClick}>
            <button>{clicked ? <FaTimes /> : <FaBars />}</button>
          </MobileButton>
        </NavBarContainer>
      </ThemeProvider>
    </>
  );
};

export default NavBar;
